<template>
    <div class="index">
        <table-page-template
            v-bind="templateConfig"
            :paging-config.sync="getTeacherListParams"
            :total-count="totalCount"
            @page-change="getTeacherTableData">
            
            <template #tableOperate="{ item }">
                <el-link type="primary" :underline="false" @click="edit(item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="deleteTeacher(item)">删除</el-link>
            </template>
        </table-page-template>
        <my-dialog
            class="operate-teacher"
            :title="createDialogTitle"
            :confirm-handler="confirmOperate"
            padding="40px 0 18px"
            :cancel-handler="clearTeacherFormData"
            :is-show.sync="operateDialog"
            :top = "40">

            <div class="form-box">
                <pack-el-form 
                    ref="elForm"
                    :rules="rules"
                    :params.sync="createTeacherParams"
                    :form-config="formConfig"
                    label-width="80">
                </pack-el-form>
            </div>
        </my-dialog>
        <my-dialog class="delete-school" :is-show.sync="deleteDialog" title="提醒消息" :confirm-handler="confirmDelete">
            <div class="content">是否删除该教师, 删除后将无法恢复！</div>
        </my-dialog>
    </div>
</template>

<script>
    import TablePageTemplate from 'components/common/TablePageTemplate'
    import MyDialog from 'components/common/MyDialog'
    import PackElForm from 'components/common/PackElForm'
    import { getTeacherListData, addTeacher, uploadTeacherAvatar, editTeacher, delTeacher } from '@/utils/apis'

    export default {
        name: "Index",
        data() {
            return {
                teacherTableData: [],
                getTeacherListParams: {
                    page: 1,
                    limit: 10
                },
                createTeacherParams: {
                    nickname: "",
                    username: "",
                    password: "",
                    avatar: "",
                    id: -1,
                },
                rules: {
                    username: [
                        { required: true, message: "请输入账号", trigger: "blur" },
                        { min: 2, message: "2-20个字", trigger: "blur" }
                    ],
                    nickname: [
                        { required: true, message: "请输入名称", trigger: "blur" },
                        { min: 2, message: "2-18个字", trigger: "blur" }
                    ],
                    password: [
                        { required: true, message: "请输入密码", trigger: "blur" },
                        { min: 2, message: "6-20个字", trigger: "blur" }
                    ],
                },
                totalCount: 1,
                createDialogTitle: "",
                uploadImgPath: "teacher_manage/upload",
                deleteId: -1,
                operateDialog: false,
                deleteDialog: false,
                host: localStorage.getItem("hosturl")
            }
        },
        components: {
            TablePageTemplate,
            MyDialog,
            PackElForm
        },
        computed: {
            templateConfig() {
                return {
                    tableData: this.teacherTableData,
                    rightConfig: {
                        text: "创建教师",
                        handler: this.createTeacher,
                    },
                    tableConfig: [
                        { label: "教师名称", prop: "nickname", width: 400 },
                        { label: "头像", prop: "avatar", isImg: true, align: "center" },
                        { label: "教师账号", prop: "username", align: "center" },
                        { label: "操作", width: 300, align: "center" },
                    ],
                };
            },
            formConfig() {
                return [
                    {
                        label: "教师名称",
                        prop: "nickname",
                        placeholder: "2-18个字",
                        max: 18,
                    },
                    {
                        label: "账号",
                        prop: "username",
                        placeholder: "2-20个字",
                        max: 20,
                    },
                    {
                        label: "密码",
                        prop: "password",
                        placeholder: "6-20个字",
                        max: 20,
                    },
                    {
                        label: "头像",
                        prop: "avatar",
                        type: "img",
                        uploadPath: this.uploadImgPath,
                        clear: this.clearAvatar,
                        uploadSuccess: this.uploadSuccess,
                        uploadErr: this.uploadErr,
                    },
                ]
            },
        },
        methods: {
            init() {
                this.getTeacherTableData()
            },
            async getTeacherTableData() {
                let res = await getTeacherListData(this.getTeacherListParams);
                this.totalCount = res.data.total;
                this.teacherTableData = res.data.list;
            },
            createTeacher() {
                this.createDialogTitle = "创建教师";
                this.operateDialog = true;
                this.createTeacherParams.nickname = "";
                this.createTeacherParams.username = "";
                this.createTeacherParams.password = "";
                this.createTeacherParams.id = "";
                this.clearAvatar();
            },
            async confirmOperate() {
                if (this.createTeacherParams.id == '') {
                    let avatar = this.createTeacherParams.avatar.replace(this.host, "");
                    if (this.createTeacherParams.password == '') {
                        return this.$message.warning('密码不能为空！')
                    }
                    let res = await addTeacher({...this.createTeacherParams, avatar});
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                    } else {
                        this.$message.error(res.msg)
                    }
                } else if (this.createTeacherParams.id != '') {
                    let avatar = '';
                    if (this.createTeacherParams.avatar == null) {
                        avatar = '';
                    } else {
                        avatar = this.createTeacherParams.avatar && this.createTeacherParams.avatar.replace(this.host, "");
                    };
                    let res = await editTeacher({...this.createTeacherParams, avatar});
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                    } else {
                        this.$message.error(res.msg);
                    };
                }
                this.getTeacherTableData();
                this.operateDialog = false;
            },
            clearTeacherFormData() {},
            edit(item) {
                this.createDialogTitle = "编辑教师";
                this.operateDialog = true;
                this.createTeacherParams.id = item.id;
                this.createTeacherParams.nickname = item.nickname;
                this.createTeacherParams.username = item.username;
                this.createTeacherParams.avatar = item.avatar;
                this.createTeacherParams.password = "";
            },
            deleteTeacher(item) {
                this.deleteId = item.id;
                this.deleteDialog = true;
            },
            async confirmDelete() {
                let res = await delTeacher(this.deleteId);
                if (res.code == 200) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                };
                this.getTeacherTableData();
                this.deleteDialog = false;
            },
            clearAvatar() {
                this.createTeacherParams.avatar = "";
            },
            uploadSuccess(res, file, fileList) {
                this.createTeacherParams.avatar = `${this.host}${res.data.src}`;
                this.$message.success(res.msg);
            },
            uploadErr() {
                this.$message.error("上传失败，请重新上传");
            },
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped lang="scss">
    .index {
        box-sizing: border-box;
        height: 100%;
        padding: 20px 20px 0;
    }
</style>